// @ts-nocheck
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import useResizeObserver from "@react-hook/resize-observer";
// import useResizeAware from "react-resize-aware";
import { useSearch, useNavigate, Link } from "@tanstack/react-location";

import {
  useThrottle,
  useDebouncedValue,
  useRefElement,
  useMergeRefs,
  useDimensionsRef,
} from "rooks";
import { useHash } from "react-use";

import useAppStore from "../../store/app";

// @ts-ignore
import KeyboardEventHandler from "react-keyboard-event-handler";

import useSingleQuery from "../../hooks/useSingleQuery";
import useExistsQuery from "../../hooks/useExistsQuery";
import useUserQuery from "../../hooks/useUserQuery";
import useDocUpdate from "../../hooks/useDocUpdate";

// import PermissionsHeader from "./PermissionsHeader";

import useRealtime from "../hooks/useRealtime";

import { useOurEditor, RenderEditor } from "./Editor/Editor";
// import { MenuBar } from "./Editor/MenuBar";

let lastHash;

const ViewDoc = ({ user }) => {
  const [isSynced, setSynced] = useState(false);
  const [title, setTitle] = useState("");

  const setCurrentlyViewing = useAppStore((s) => s.setCurrentlyViewing);
  const search = useSearch();
  const navigate = useNavigate();

  const {
    data: doc,
    isLoading: isLoadingDoc,
    isError: isErrorDoc,
    refetch: refetchDoc,
  } = useSingleQuery({
    table: "docs",
    id: search.id,
    select: `
      id,
      title,
      owner,
      created_at,
      owner_full:owner (
          name
      )`,
  });

  const {
    data: existsObj,
    isLoading: isLoadingExistsObj,
    isError: isErrorExistsObj,
    refetch: refetchExistsObj,
  } = useExistsQuery({
    table: "docs",
    pk: search.id,
  });

  const updateDoc = useDocUpdate();
  const handleSave = async () => {
    await updateDoc.mutateAsync({
      id: doc.id,
      title: 'test title ' + Date.now() //title,
    });
    refetchDoc();
  };

  const handleKeyEvent = (key: any, e: any) => {
    //   if (!canEdit) return;
    switch (key) {
      case "ctrl+s":
      case "meta+s":
      case "cmd+s":
        handleSave();
        e.preventDefault();
        break;
      default:
        break;
    }
  };

  const onSynced = useCallback(() => {
    setSynced(true);
  }, []);

  const handleEditorUpdate = (editor) => {
    // console.log("updated editor");
    buildToc(editor);
  };

  const editor = useOurEditor({
    id: search.id,
    user,
    onSynced,
    onUpdate: handleEditorUpdate,
  });

  const [hash, setHash] = useHash();
  const [toc, setToc] = useState(null);
  const scrollContainerRef1 = useRef(null);
  const [scrollContainerRef2, scrollContainerElement] = useRefElement();
  const scrollContainerRef = useMergeRefs(
    scrollContainerRef1,
    scrollContainerRef2
  );

  const size = useSize(scrollContainerRef1);

  useEffect(() => {
    // console.log("upated sizes");
    buildToc(editor);
  }, [size?.width, size?.height, editor]);

  // console.log("toc:", { toc, size });
  const buildToc = (editor) => {
    if (!editor) {
      return null;
    }
    // const headings = [];
    // let minDepth = 6;
    // function getText(item) {
    //   let text = "";
    //   if (item.type === "text") {
    //     text = `${text}${item.text}`;
    //   } else {
    //     if (item.content) {
    //       for (let i2 of item.content) {
    //         const t2 = getText(i2);
    //         text = `${text}${t2}`;
    //       }
    //     }
    //   }
    //   return text;
    // }
    // function check(item) {
    //   const skipItems = ["details"];
    //   if (item.type === "heading") {
    //     // get text for heading
    //     let text = getText(item);
    //     headings.push({
    //       id: text,
    //       title: text,
    //       depth: item.attrs.level,
    //     });
    //     minDepth = minDepth < item.attrs.level ? minDepth : item.attrs.level;
    //   } else {
    //     if (!skipItems.includes(item.type)) {
    //       if (item.content) {
    //         for (let i2 of item.content) {
    //           check(i2);
    //         }
    //       }
    //     }
    //   }
    // }
    // check(editor.getJSON());
    // setToc({ minDepth, headings });
  };
  useEffect(() => {
    if (
      hash?.length > 1 &&
      hash !== lastHash &&
      isSynced &&
      toc !== null &&
      !lastHash
    ) {
      lastHash = hash;
      let item = toc?.headings?.find((i) => `#${i.id}` === hash);
      if (item) {
        setTimeout(() => {
          scrollContainerElement.scrollTo(0, item.top);
        }, 1);
      }
    }
  }, [hash, isSynced, JSON.stringify(toc)]);

  useEffect(() => {
    if (editor && scrollContainerElement && isSynced) {
      // console.log("have element now", scrollContainerElement);
      // setTimeout(buildToc, 1);
      buildToc(editor);
    }
  }, [scrollContainerElement, isSynced, editor]);

  if ((!doc && isLoadingDoc) || (!existsObj?.data && isLoadingExistsObj)) {
    return (
      <div className="h-full flex items-center justify-center text-center">
        <div>
          <LoadingIcon />
          <div className="mt-2 text-sm text-gray-400">Loading Document</div>
        </div>
      </div>
    );
  }

  if (!doc && isErrorDoc) {
    // just hidden to user, or does not actually exist?
    if (existsObj?.data) {
      return (
        <div className="h-full flex items-center justify-center text-center">
          <div>
            <ExclamationTriangleIcon className="h-5 w-5 inline-block text-orange-500" />
            <div className="mt-2 text-sm text-orange-500">
              You do not have the necessary permissions to view this document.
              <br />
              Contact the document owner for access:{" "}
              <span className="font-bold">
                <Owner
                  id={existsObj.data.owner_id}
                  owner={existsObj.data.owner}
                />
              </span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="h-full flex items-center justify-center text-center">
          <div>
            <ExclamationTriangleIcon className="h-5 w-5 inline-block text-red-400" />
            <div className="mt-2 text-sm text-red-400">
              Error loading document
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <KeyboardEventHandler
        handleKeys={["meta+s", "ctrl+s", "meta"]}
        onKeyEvent={handleKeyEvent}
        handleFocusableElements
      />
      <div className="border-b bg-white">
        <div className="flex items-center space-x-1 px-1 py-1">
          {/* <div className="">
            <PermissionsHeader
              obj={doc}
              user={user}
              updateObj={updateDoc}
              refetch={refetchDoc}
            />
          </div> */}
          <div className="text-sm">
            <Owner id={doc.owner_id} owner={doc.owner} />
          </div>
          {/* <div className="pl-2">
            <CopyLink link={`/?type=doc&id=${doc.id}`} />
          </div> */}
          <div className="flex-auto"></div>
        </div>
      </div>
      {/* <div
        className="border-b"
      >
        <input
          disabled={status === "view" ? true : false}
          className="w-full text-xl px-2 py-1 outline-none bg-white"
          onChange={(e) => {
            setTitle(e.target.value);
            // throttledFunction(realSaveNum + 1);
            setAutosaveNum((v) => v + 1);
          }}
          value={title}
          placeholder="Untitled"
        />
      </div> */}
      <div className={`flex flex-col overflow-hidden`}>
        {/* <div className={`${status !== "edit" ? "hidden" : ""}`}>
          <div className={`border-b`}>
            <MenuBar editor={editor} />
          </div>
        </div> */}
        <div
          ref={scrollContainerRef}
          className={`h-full px-2 py-1 flex bg-white`}
        >
          {/* {toc?.headings?.length ? (
            <div className="flex-none w-[220px] border-r p-2 mr-2 h-full scroll-smooth overflow-y-auto ">
              Contents
              <br />
              {toc.headings.map((item, i) => {
                // console.log("item:", item.id, item.top);
                // console.log("minDepth:", toc.minDepth);
                return (
                  <div
                    key={i}
                    className="text-ellipsis overflow-hidden whitespace-nowrap "
                  >
                    <Link
                      // hash={`${item.id}`}
                      // to="."
                      // search={(s) => s}
                      onClick={(e) => {
                        setHash(item.id);
                        // scrollContainerRef.current.scrollTo(0, item.top);
                        e.preventDefault();
                        return false;
                      }}
                      className={`underline text-sm ml-${
                        (item.depth - 1) * 2 // not sure why this isn't working..
                      }`}
                      style={{
                        marginLeft: (item.depth - toc.minDepth) * 8,
                      }}
                    >
                      {item.title}
                    </Link>
                  </div>
                );
              })}
            </div>
          ) : null} */}
          <div className="flex-auto h-full scroll-smooth overflow-y-auto prose max-w-none">
            {isSynced ? (
              <RenderEditor editor={editor} />
            ) : (
              "Syncing document..."
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const LoadingIcon = () => {
  return (
    <svg
      className="animate-spin h-5 w-5 inline-block text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const Owner = ({ id, owner }) => {
  const { data: user } = useUserQuery({ id: owner ? null : id });
  return owner?.email?.split("@")[0] ?? user?.email?.split("@")[0] ?? "";
};

const useSize = (target) => {
  const [size, setSize] = React.useState();

  React.useLayoutEffect(() => {
    setSize(target.current?.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

export default ViewDoc;
