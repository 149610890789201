// @ts-nocheck
import { useQuery } from "react-query";
import { supabase } from "../lib/api";

const getExists = async ({ table, pk }) => {
  const {
    data: data,
    error: error,
    // @ts-ignore
  } = await supabase.functions.invoke("check-pk-exists", {
    body: { table, pk },
  });
  // console.log("check-pk-exists:", { data, error });

  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error("Unable to get valid result");
  }

  return data;
};

export default function useSingleQuery({ table, pk }) {
  return useQuery([table, "pk", pk], () => getExists({ table, pk }), {
    enabled: !!pk,
  });
}
