// @ts-nocheck
import { useRef, useState } from "react";
import { supabase } from "../lib/api";
import toast from "react-hot-toast";

const Auth = () => {
  const [helperText, setHelperText] = useState({ error: null, text: null });
  const [didSendMagicLink, sendMagicLink] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();

  const handleLogin = async (type) => {
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    const { user, error } =
      type === "LOGIN"
        ? await supabase.auth.signIn({ email, password })
        : await supabase.auth.signUp({ email, password });

    if (error) {
      setHelperText({ error: true, text: error.message });
    } else if (!user && !error) {
      setHelperText({
        error: false,
        text: "An email has been sent to you for verification!",
      });
    }
  };

  const handleOAuthLogin = async (provider) => {
    // You need to enable the third party auth you want in Authentication > Settings
    // Read more on: https://supabase.com/docs/guides/auth#third-party-logins
    let { error } = await supabase.auth.signInWithOAuth({ provider });
    if (error) console.log("Error: ", error.message);
  };

  const handleMagicLogin = async () => {
    // You need to enable the third party auth you want in Authentication > Settings
    // Read more on: https://supabase.com/docs/guides/auth#third-party-logins

    const email = emailRef.current?.value;
    const signinPromise = supabase.auth.signInWithOtp({ email });

    toast.promise(signinPromise, {
      loading: "Sending magic link...",
      success: <b>Successfully sent link, check your email!</b>,
      error: <b>Failed sending, please try again</b>,
      position: "top-center",
    });

    let { error } = await signinPromise;

    if (error) console.log("Error: ", error.message);
    else sendMagicLink(true);
  };

  const forgotPassword = async (e) => {
    // Read more on https://supabase.com/docs/reference/javascript/reset-password-email#notes
    e.preventDefault();
    const email = prompt("Please enter your email:");

    if (email === null || email === "") {
      setHelperText({ error: true, text: "You must enter your email." });
    } else {
      let { error } = await supabase.auth.api.resetPasswordForEmail(email);
      if (error) {
        console.error("Error: ", error.message);
      } else {
        setHelperText({
          error: false,
          text: "Password recovery email has been sent.",
        });
      }
    }
  };

  return (
    <div
      className={
        "w-full h-full rounded shadow-md sm:h-auto sm:w-2/5 max-w-sm p-5 bg-white shadow flex flex-col text-base"
      }
    >
      {/* <span
                className={
                    "font-sans text-4xl text-center pb-2 mb-1 border-b mx-4 align-center"
                }
            >
                Login
            </span> */}

      {didSendMagicLink ? (
        <div className="text-center">Link sent via email!</div>
      ) : (
        <>
          {/* <div className="mt-2 flex">
            <span className="block w-full mx-1.5 rounded-md shadow-sm">
              <button
                onClick={() => handleOAuthLogin("azure")}
                type="button"
                className="flex w-full justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition duration-150 ease-in-out"
              >
                via Microsoft Azure
              </button>
            </span>
          </div> */}

          <input
            className={"bg-gray-100 border py-1 px-3"}
            type={"email"}
            name={"email"}
            placeholder="Email Address"
            ref={emailRef}
            required
          />
          {!!helperText.text && (
            <div
              className={`border px-1 py-2 my-2 text-center text-sm ${
                helperText.error
                  ? "bg-red-100 border-red-300 text-red-400"
                  : "bg-green-100 border-green-300 text-green-500"
              }`}
            >
              {helperText.text}
            </div>
          )}
          <div className="mt-2 flex">
            <span className="block w-full mx-1.5 rounded-md shadow-sm">
              <button
                onClick={() => handleMagicLogin()}
                type="button"
                className="flex w-full justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-emerald-700 transition duration-150 ease-in-out"
              >
                Send Login Link via Email
              </button>
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default Auth;
