// @ts-nocheck
import React, { useEffect, useState, useRef, useCallback } from "react";
import { supabase } from "../lib/api";
import { UserGroupIcon } from "@heroicons/react/outline";
import { cx, css } from "@emotion/css";

// import { Slice, Fragment, Node } from "prosemirror-model";
import { useEditor, EditorContent, BubbleMenu } from "@tiptap/react";
import { ReactNodeViewRenderer, NodeViewWrapper } from "@tiptap/react";
import * as Y from "yjs";
import { HocuspocusProvider } from "@hocuspocus/provider";

import { useSearch, useNavigate, Link } from "@tanstack/react-location";

import { useHash } from "react-use";
import useAppStore from "../../../store/app";

// @ts-ignore
import KeyboardEventHandler from "react-keyboard-event-handler";

// import { Node as TipTapNode, mergeAttributes, textblockTypeInputRule } from "@tiptap/core";
// import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import BubbleMenuExtension from "@tiptap/extension-bubble-menu";
import Heading from "@tiptap/extension-heading";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import ListItem from "@tiptap/extension-list-item";
import LinkExtension from "@tiptap/extension-link";
// import CustomLinkExtension from "../extension-link/src";
import Placeholder from "@tiptap/extension-placeholder";
// import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
// import Details from "@tiptap-pro/extension-details";
// import DetailsSummary from "@tiptap-pro/extension-details-summary";
// import DetailsContent from "@tiptap-pro/extension-details-content";
import { Collaboration } from "@tiptap/extension-collaboration";
import { ySyncPlugin, yUndoPlugin, yUndoPluginKey } from "y-prosemirror";

import MenuBar, { MenuButtonIcon } from "./MenuBar";

export const CollaborationExtension = Collaboration.extend({
  // we use this because "history" of the CollaborationExtension breaks when using a BubbleMenu
  // - https://github.com/yjs/y-prosemirror/issues/114#issuecomment-1180235892
  // - solution is to call the yUndoPlugin last (after everything else)
  addProseMirrorPlugins() {
    const fragment = this.options.fragment
      ? this.options.fragment
      : this.options.document.getXmlFragment(this.options.field);

    const yUndoPluginInstance = yUndoPlugin();
    const originalUndoPluginView = yUndoPluginInstance.spec.view;
    yUndoPluginInstance.spec.view = (view) => {
      const undoManager = yUndoPluginKey.getState(view.state).undoManager;
      if (undoManager.restore) {
        undoManager.restore();
        undoManager.restore = () => {};
      }
      const viewRet = originalUndoPluginView(view);
      return {
        destroy: () => {
          const hasUndoManSelf = undoManager.trackedOrigins.has(undoManager);
          const observers = undoManager._observers;
          undoManager.restore = () => {
            if (hasUndoManSelf) {
              undoManager.trackedOrigins.add(undoManager);
            }
            undoManager.doc.on(
              "afterTransaction",
              undoManager.afterTransactionHandler
            );
            undoManager._observers = observers;
          };
          viewRet.destroy();
        },
      };
    };
    return [ySyncPlugin(fragment), yUndoPluginInstance];
  },
});

// const CustomLinkExtension = LinkExtension.extend({
//   addNodeView() {
//     console.log("extended LinKExtension");
//     return ReactNodeViewRenderer(Component);
//   },
// });
// const Component = ({ editor, getPos, ...rest }: any) => {
//   console.log("REST:", rest);
//   return (
//     <NodeViewWrapper className="link">
//       <a href="/">link</a>
//     </NodeViewWrapper>
//   );
// };
const CustomListItem = ListItem.extend({
  content: "block*",
});
// const CustomDetails = Details.extend({ // using this causes an error: "Duplicate use of selection JSON ID gapcursor"
//   draggable: true,
// });

export const useOurEditor = ({ id, user, onSynced, onUpdate }) => {
  const session = useAppStore((s) => s.session);

  const [ydoc] = useState(() => {
    return new Y.Doc();
  });

  const [provider] = useState(() => {
    // console.log(
    //   "session access_token:",
    //   session?.access_token?.length ? true : false
    // );
    // if (!session?.access_token?.length) {
    //   debugger;
    // }
    return new HocuspocusProvider({
      url: process.env.REACT_APP_COLLABSERVER_URL,
      name: `docs.${id}`,
      document: ydoc,
      token: session?.access_token,
    });
  });

  useEffect(() => {
    provider.on("synced", onSynced);
    return () => {
      provider.off("synced", onSynced);
    };
  }, []);

  const editor = useEditor({
    // editable: status === "edit" ? true : false,
    // editorProps: {
    //   clipboardTextParser, // note that using parseOptions.preserveWhitespace="full" does NOT work!
    // },
    // parseOptions: {
    //   preserveWhitespace: "full", // this doesnt work, we have to use clipboardTextParser (above)
    // },
    onUpdate: ({ editor }) => {
      // // console.log("onUpdate:", editor.getJSON(), editor.getText());
      // const prosemirrorJSON = editor.getJSON();
      // const text = prosemirrorJSON.content
      //   .map((v) => {
      //     return v.content?.[0]?.text;
      //   })
      //   .join("\n");
      // // console.log("text:", text);
      // // onUpdateText(editor.getText()); // strips empty lines!!
      // // onUpdateText(text);
      onUpdate(editor);
    },
    extensions: [
      StarterKit.configure({
        // The Collaboration extension comes with its own history handling
        history: false,
        // heading: false, // overwrite with our custom Heading
        listItem: false,
      }),
      Underline.configure({
        HTMLAttributes: {
          // class: "my-custom-class",
        },
      }),
      CustomListItem.configure({}),
      // // CustomHeading.configure({ HTMLAttributes: {} }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      LinkExtension.configure({
        openOnClick: false,
      }),
      // // Image.configure({
      // //   // HTMLAttributes: {
      // //   //   class: "standard",
      // //   // },
      // //   // inline: true,
      // //   // // allowBase64: true, // not sure if needed...
      // // }),
      // // Video.configure({
      // //   HTMLAttributes: {
      // //     class: "standard",
      // //     muted: true,
      // //     loop: true,
      // //     controls: true, // if "standard" then controls are not shown
      // //   },
      // //   // inline: true,
      // // }),
      // Details.configure({
      //   // CustomDetails
      //   persist: true,
      //   HTMLAttributes: {
      //     class: "details",
      //   },
      // }),
      // DetailsSummary,
      // DetailsContent,
      // TableOfContents.configure({}),
      // Columns.configure({}),
      // Column.configure({}),
      Placeholder.configure({
        includeChildren: true,
        placeholder: ({ node, pos, ...rest }) => {
          // console.log("editor", node, pos, { rest });
          if (node.type.name === "detailsSummary") {
            return "Summary";
          }

          // TODO: the following doesnt work because it is actually in a "paragraph" that is inside the "detailsContent"
          if (node.type.name === "detailsContent") {
            return "Write content here";
          }

          if (pos === 0) {
            return "Write something here";
          }

          return "";
        },
      }),
      CollaborationExtension.configure({
        document: ydoc,
      }),
      CollaborationCursor.configure({
        provider,
        user: {
          name: user?.name?.split("@")[0] ?? 'unknown',
          color: stringToColor(user?.name ?? 'unknown'),
        },
      }),
    ],
  });

  return editor;
};

export const RenderEditor = ({ editor }) => {
  return (
    <>
      <EditorContent
        editor={editor}
        className={`${cx(
          css(`
              .ProseMirror-focused {
                  outline: none;
              }

              /* Placeholder (at the top) */
              .ProseMirror p.is-editor-empty:first-child::before {
                color: #adb5bd;
                content: attr(data-placeholder);
                float: left;
                height: 0;
                pointer-events: none;
              }

              /* Give a remote user a caret */
              .collaboration-cursor__caret {
                border-left: 1px solid #0d0d0d;
                border-right: 1px solid #0d0d0d;
                margin-left: -1px;
                margin-right: -1px;
                pointer-events: none;
                position: relative;
                word-break: normal;
              }

              /* Render the username above the caret */
              .collaboration-cursor__label {
                border-radius: 3px 3px 3px 0;
                color: #0d0d0d;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                left: -1px;
                line-height: normal;
                padding: 0.1rem 0.3rem;
                position: absolute;
                top: -1.4em;
                user-select: none;
                white-space: nowrap;
              }

              .ProseMirror {

                summary {
                  display: block; // overwrite's tailwind, which changes summary.display=list-item
                }

                .details {
                  display: flex;
                  // margin: 1rem 0;
                  // border: 1px solid black;
                  // border-radius: 0.5rem;
                  // padding: 0.5rem;

                  > button,
                  > span {
                    display: flex;
                    cursor: pointer;
                    background: transparent;
                    border: none;
                    padding: 0;

                    &::before {
                      content: "\\25B6";
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      // width: 1.5em;
                      padding-right: 8px;
                      height: 1.5em;
                    }
                  }

                  &.is-open > button::before {
                    content: "\\25BC";
                  }

                  > div {
                    flex: 1 1 auto;
                  }

                  :last-child {
                    margin-bottom: 0;
                  }
                }

              }

              `)
        )}`}
      />
    </>
  );
};

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}


// {editor && (
//         <BubbleMenu
//           editor={editor}
//           tippyOptions={{ duration: 100, maxWidth: "600px" }}
//           shouldShow={({ editor, view, state, oldState, from, to }) => {
//             // only show the bubble menu for images and links
//             return editor.isActive("link");
//           }}
//         >
//           <div className="w-fit border-2 rounded-sm bg-white text-slate-500">
//             <div className="p-2">
//               <a href={editor.getAttributes("link").href}>
//                 {editor.getAttributes("link").href}
//               </a>
//             </div>
//             <div className="flex items-center w-fit ">
//               {/* <MenuButtonIcon
//                 // tooltipTitle="Small"
//                 onClick={() => {
//                   window.open(editor.getAttributes("link").href, "_blank");
//                   // editor
//                   //   .chain()
//                   //   .focus()
//                   //   .setImage({ size: "small" })
//                   //   .setNodeSelection(editor.view.state.selection.from)
//                   //   .run();
//                 }}
//                 active={editor.isActive("image", {
//                   size: "small",
//                 })}
//                 icon={<span>open (in new tab)</span>}
//               /> */}
//               <MenuButtonIcon
//                 // tooltipTitle="Small"
//                 onClick={() => {
//                   const href = window.prompt(
//                     "link:",
//                     editor.getAttributes("link").href
//                   );
//                   if (!href) return;
//                   editor
//                     .chain()
//                     .focus()
//                     .extendMarkRange("link")
//                     .setLink({ href })
//                     .run();
//                 }}
//                 active={editor.isActive("image", {
//                   size: "small",
//                 })}
//                 icon={<span>change link</span>}
//               />
//               {/* <MenuButtonIcon
//               // tooltipTitle="Small"
//               onClick={() => {
//                   // const href = window.prompt(
//                   //   "link:",
//                   //   editor.getAttributes("link").href
//                   // );
//                   // if (!href) return;
//                   editor
//                     .chain()
//                     .focus()
//                     .extendMarkRange("link")
//                     .setText({ href }) // this does not exist!
//                     .run();
//               }}
//               active={editor.isActive("image", {
//                 size: "small",
//               })}
//               icon={<span>change text</span>}
//             /> */}
//               <MenuButtonIcon
//                 // tooltipTitle="Small"
//                 onClick={() => {
//                   editor
//                     .chain()
//                     .focus()
//                     // .setImage({ size: "small" })
//                     .unsetLink()
//                     // .setNodeSelection(editor.view.state.selection.from)
//                     .run();
//                 }}
//                 active={editor.isActive("image", {
//                   size: "small",
//                 })}
//                 icon={<span>remove link</span>}
//               />
//               {/* <a href="/?test">this is the testing text</a> */}
//             </div>
//           </div>
//         </BubbleMenu>
//       )}
