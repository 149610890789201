// @ts-nocheck
import { useEffect, useState, useRef } from "react";
import { supabase } from "../lib/api";
import Doc from "./View/Doc";

import { Link, useSearch } from "@tanstack/react-location";

const Home = ({ user }) => {
  const search = useSearch();

  return (
    <div className={"w-screen fixed flex flex-col min-h-screen bg-gray-50"}>
      <div
        className={"flex flex-row flex-grow divide-x"}
        style={{ height: "calc(100vh - 11.5rem)" }}
      >
        <div className="flex flex-col flex-auto bg-white">
          {
            search.id ? <Doc key={search.id} user={user} />:null
          }
        </div>
      </div>
    </div>
  );
};

export default Home;
